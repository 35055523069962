import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TimeAgo from '../time-ago';
import Link from '../link/internal-link';
import UserAvatarImage from '../user-avatar-image';
import MemberCardAsync from '../../containers/member-card-async';
import styles from './post-list-item-recent-activity.scss';
import AvatarImage from '../avatar-image';
import { POST_LIST_ITEM_RECENT_ACTIVITY } from '../../constants/bi-locations';
import { withErrorBoundary } from '@wix/communities-forum-client-commons/components';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { ArrowLeftIcon } from '../icons/arrow-left-icon';
import { ArrowRightIcon } from '../icons/arrow-right-icon';
import { withDirection, DIRECTIONS } from '../../../common/react-with-direction';

import { buildNewDeepCommentUrl } from '../../services/build-deep-comment-url';

class PostListItemRecentActivity extends Component {
  render() {
    const { post, category, direction, withDirectionIcon, withText, isAvatarSmall, t } = this.props;

    let date;
    let user;
    let url;
    let dynamicLocationResolver;

    const hasRecentActivity = post.recentActivity && post.recentActivity.activityDate;
    if (hasRecentActivity) {
      date = post.recentActivity.activityDate;
      user = post.recentActivity.initiator;
      url = buildNewDeepCommentUrl(category.slug, post.slug, post.recentActivity.entityId);
    } else {
      date = post.lastActivityDate || post.createdDate;
      user = post.owner;
      url = `/${category?.slug}/${post?.slug}`;
    }

    return (
      <Link
        to={url}
        asClickHandler={!hasRecentActivity}
        dynamicLocationResolver={dynamicLocationResolver}
        className={classNames('forum-text-color', 'forum-link-hover-color', styles.link)}
        biLocation={POST_LIST_ITEM_RECENT_ACTIVITY}
        data-hook="post-list-item__recent-activity"
        aria-label={t('post-list-item.recent-activity-nav-a11y')}
      >
        <MemberCardAsync
          viewedMemberId={user && user.siteMemberId}
          viewedMemberSlug={user && user.slug}
          stopPropagation={false}
        >
          <UserAvatarImage
            user={user}
            type={isAvatarSmall ? AvatarImage.SMALL : AvatarImage.MEDIUM}
            className={styles.avatar}
          />
        </MemberCardAsync>

        {withText && (
          <span className={styles.text}>
            {t(hasRecentActivity ? 'recent-activity.replied' : 'recent-activity.posted')}&nbsp;
          </span>
        )}
        <TimeAgo className={styles.timeAgo} time={date} />
        {withDirectionIcon && direction === DIRECTIONS.RTL && (
          <ArrowLeftIcon className={classNames('forum-icon-fill', styles.directionIcon)} />
        )}
        {withDirectionIcon && direction === DIRECTIONS.LTR && (
          <ArrowRightIcon className={classNames('forum-icon-fill', styles.directionIcon)} />
        )}
      </Link>
    );
  }
}

PostListItemRecentActivity.propTypes = {
  post: PropTypes.object,
  category: PropTypes.object,
  t: PropTypes.func,
  withDirectionIcon: PropTypes.bool,
  withText: PropTypes.bool,
  isAvatarSmall: PropTypes.bool,
  buildDeepLink: PropTypes.func,
};

export default flowRight(
  withErrorBoundary,
  withTranslate,
  withDirection,
)(PostListItemRecentActivity);
